import { Navigate } from "react-router-dom";
import { ReactNode } from "react";

export const ProtectedRoute = ({
  children,
  isAllowed,
  user,
}: {
  children: ReactNode;
  isAllowed: string;
  user: any;
}): JSX.Element => {
  if (!user) {
    return <Navigate to="/" />;
  }

  if (user.usertypeid === 2) {
    return <Navigate to="/customer-order" />;
  }

  if (!user.modules?.includes(isAllowed)) {
    return <Navigate to="/home" />;
  }

  return <>{children}</>;
};
